<!--
 * @Author: your name
 * @Date: 2021-12-08 18:22:11
 * @LastEditTime: 2021-12-28 16:15:51
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/defAddress/curd/_edit.vue
-->
<template>
    <div class="nof-outer">
        <a-form-model ref="noFbaForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="国家" prop="countryId" :rules="validate({name: 'value', msg: '请选择国家'})">
                <a-select show-search v-model="form.countryId" placeholder="选择国家" style="width: 100%" :default-active-first-option="false" :show-arrow="true" :filter-option="false" :not-found-content="null" @search="searchCountry" @change="handleChange">
                    <a-select-option v-for="c in countryList" :key="c.id" :value="c.id">
                        {{ c.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
             <a-form-model-item label="城市" prop="city" :rules="validate({name: 'value', msg: '请输入姓名'})">
                <a-input v-model="form.city" placeholder="城市" allowClear></a-input>
            </a-form-model-item>
             <a-form-model-item label="州" prop="state" >
                <a-input v-model="form.state" placeholder="州" allowClear></a-input>
            </a-form-model-item>
             <a-form-model-item label="公司" prop="corporation">
                <a-input v-model="form.corporation" placeholder="公司" allowClear></a-input>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="name" :rules="validate({name: 'value', msg: '请输入姓名'})">
                <a-input v-model="form.name" placeholder="姓名" allowClear></a-input>
            </a-form-model-item>
            <a-form-model-item label="电话" prop="phone">
                <a-input v-model="form.phone" placeholder="电话" allowClear></a-input>
            </a-form-model-item>
            <a-form-model-item label="邮箱" prop="email" :rules="validate({name: 'isMail'})">
                <a-input v-model="form.email" placeholder="邮箱" allowClear></a-input>
            </a-form-model-item>
            <a-form-model-item label="邮编" prop="zipCode" :rules="validate({name: 'value', msg: '请输入邮编'})">
                <a-input v-model="form.zipCode" placeholder="邮编" allowClear></a-input>
            </a-form-model-item>
            <a-form-model-item label="区号" prop="countryAreaCode" >
                <a-input-number v-model="form.countryAreaCode" style="width: 100%" placeholder="区号" allowClear></a-input-number>
            </a-form-model-item>
            <a-form-model-item label="地址" prop="address" :rules="validate({name: 'value', msg: '请输入地址'})">
                <a-input v-model="form.address" type='textarea' style="width: 100%" placeholder="地址" allowClear></a-input>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import { countryList } from "@/api/comm";
import { addressNoFbaAdd, addressNoFbaEdit, addressNoFbaInfo } from "@/api/Address";
import {hump} from "@/utils/prarms"
export default {
    props: {
        type: {
            type: String,
            default: "add",
        },
        mailAddressId: {
            type: [String, Number],
            default: "",
        },
    },
    watch: {
        type: {
            handler(val) {
                if (val === "edit") {
                    addressNoFbaInfo({mailAddressId: this.mailAddressId}).then(res => {
                        const obj = res.data;
                        this.form = {...hump(obj)};
                        this.form.mailAddressId = this.mailAddressId;
                    })
                }
            },
            immediate: true,
        },
    },
    data() {
        return {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 },
            countryList: [],
            form: {
                mailAddressId: "",
                countryId: "",
                name: "",
                zipCode: "",
                countryAreaCode: "",
                phone: "",
                email: "",
                address: "",
            },
        };
    },
    created() {
        this.searchCountry('');
    },
    methods: {
        // 获取国家列表
        searchCountry(val) {
            let obj = {
                name: val,
                page: 1,
                limit: 10000,
            };
            countryList(obj).then((res) => {
                this.countryList = res.data.list;
            });
        },
        handleChange(val) {
            this.form.countryId = val;
        },
        submit() {
            this.$refs.noFbaForm.validate((valid) => {
                if (valid) {
                    if (this.type === "add") {
                        addressNoFbaAdd(this.form).then(() => {
                            this.$message.success("地址添加成功");
                            this.$emit("success");
                        });
                    } else {
                        addressNoFbaEdit(this.form).then(() => {
                            this.$message.success("地址编辑成功");
                            this.$emit("success");
                        });
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.nof-outer {
    width: 460px;
}
</style>
