/*
 * @Author: your name
 * @Date: 2021-12-08 18:10:06
 * @LastEditTime: 2021-12-09 11:28:10
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/defAddress/curd/columns.js
 */
export default [
    {
        title: "国家名称",
        dataIndex: "country_name",
        key: "country_name",
    },
    {
        title: "城市",
        dataIndex: "city",
        key: "city",
    },
    {
        title: "州",
        dataIndex: "state",
        key: "state",
    },
    {
        title: "公司",
        dataIndex: "corporation",
        key: "corporation",
    },
    {
        title: "姓名",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "区号",
        dataIndex: "country_area_code",
        key: "country_area_code",
    },
    {
        title: "电话",
        dataIndex: "phone",
        key: "phone",
    },
    {
        title: "邮箱",
        dataIndex: "email",
        key: "email",
    },
    {
        title: "邮编",
        dataIndex: "zip_code",
        key: "zip_code",
    },
    {
        title: "地址",
        dataIndex: "address",
        key: "address",
    },
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
    }
]