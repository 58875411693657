<!--
 * @Author: your name
 * @Date: 2021-12-08 18:04:34
 * @LastEditTime: 2021-12-28 17:31:12
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/defAddress/index.vue
-->
<template>
    <div>
        <a-form-model layout="inline" :model="form">
            <a-form-model-item label="姓名/邮编">
                <a-input v-model="form.keyword" placeholder="请输入姓名/邮编" allowClear />
            </a-form-model-item>
            <a-form-model-item>
                <a-button-group>
                    <a-button type="primary" @click="search">搜索</a-button>
                    <a-button @click="reset">重置</a-button>
                </a-button-group>
            </a-form-model-item>
        </a-form-model>
        <div align="right">
            <a-button type="primary" icon="plus" @click="add">添加</a-button>
        </div>
        <a-table size="small" :scroll="{ x: 'max-content' }" style="margin-top: 20px" bordered :columns="columns" :data-source="tableData" :loading="tableLoading"
            :pagination="pagination" :rowKey="(record,index) => index" :customRow="customRow" :rowClassName="rowClassName" @change="handlerPage">
            <template slot="action" slot-scope="record">
                <a class="btn-a" @click="handlerEdit(record)">编辑</a>
                <a-popconfirm ok-text="确定" cancel-text="取消" @confirm="handlerDel(record)">
                    <template slot="title">
                        <span>确定要删除嘛？</span>
                    </template>
                    <a class="btn-a" style="color: red">删除</a>
                </a-popconfirm>
            </template>
        </a-table>
        <a-drawer :title="modal.title" :visible="modal.visible" :width="modal.width" @close="handleCancel"
            :maskClosable="false">
            <nFba-edit v-if="modal.visible" :type="opeType" :mailAddressId="mailAddressId" ref="daRef"
                @success="handlerSuc"></nFba-edit>
            <div class="drawerFooter">
                <a-button-group>
                    <a-button @click="handleCancel">
                        取消
                    </a-button>
                    <a-button type="primary" @click="handlerSubmit">
                        确定
                    </a-button>
                </a-button-group>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import { noFbaAddress } from "@/api/comm";
import columns from "./curd/columns"
import nFba_edit from "./curd/_edit.vue"
import { addressNoFbaDel } from "@/api/Address";
export default {
    components: {
        "nFba-edit": nFba_edit
    },
    data() {
        return {
            selectIndex: null,
            opeType: 'add',
            mailAddressId: "",
            tableLoading: false,
            tableData: [],
            form: {
                keyword: "",
                limit: 20,
                page: 1,
            },
            modal: {
                title: "",
                visible: false,
                width: "700px",
            },
            pagination: {
                current: 1,
                total: 0,
                pageSize: 20,
                showTotal: (total) => `共 ${total} 条`,
                showSizeChanger: true,
                showQuickJumper: true,
            },
            columns: columns
        }
    },
    created() {
        this.getData()
    },
    methods: {
        // 点击的样式
        rowClassName(record, index) {
            return (index === this.selectIndex) ? 'row-active' : ''
        },
        // 点击的效果
        customRow(record, index) {
            return {
                on: {
                    click: () => {
                        this.selectIndex = index;
                        console.log(this.selectIndex)
                    }
                }
            }
        },
        // 获取列表数据
        getData() {
            noFbaAddress(this.form).then((res) => {
                this.tableData = res.data.list;
                this.pagination.total = res.data.total;
            });
        },
        // 切换分页
        handlerPage(p) {
            this.form.page = p.current;
            this.form.limit = p.pageSize;
            this.pagination.current = p.current;
            this.pagination.pageSize = p.pageSize;
            this.getData();
        },
        // 查询
        search() {
            this.form.page = 1;
            this.pagination.current = 1;
            this.getData();
        },
        // 关闭抽屉
        handleCancel() {
            this.modal.visible = false;
        },
        // 添加
        add() {
            this.opeType = 'add'
            this.modal.title = "添加地址"
            this.modal.visible = true;
        },
        //重置
        reset() {
            this.form.keyword = "";
        },
        // 编辑
        handlerEdit(row) {
            this.opeType = 'edit'
            this.mailAddressId = row.id;
            this.modal.title = "编辑地址"
            this.modal.visible = true;
        },
        // 删除
        handlerDel(row) {
            addressNoFbaDel({ mailAddressId: row.id }).then(() => {
                this.$message.success("地址删除成功");
                this.getData();
            })
        },
        // 提交
        handlerSubmit() {
            this.$refs.daRef.submit()
        },
        // 
        handlerSuc() {
            this.modal.visible = false;
            this.getData();
        }
    }
}
</script>

<style lang="less" scoped>

</style>