<template>
    <div class="address-container">
        <a-form-model layout="inline" :model="form">
            <a-form-model-item label="国家">
                <a-select show-search v-model="form.countryId" placeholder="选择国家" style="width: 200px"
                    :default-active-first-option="false" :show-arrow="true" :filter-option="false"
                    :not-found-content="null" @search="searchCountry" @change="handleChange">
                    <a-select-option v-for="c in countryList" :key="c.id" :value="c.id">
                        {{ c.name }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item>
                <a-button-group>
                    <a-button type="primary" @click="search">搜索</a-button>
                    <a-button @click="reset">重置</a-button>
                </a-button-group>
            </a-form-model-item>
        </a-form-model>
        <a-table size="small" style="margin-top: 20px" :rowSelection="rowSelection" :scroll="{ x:'max-content' }" bordered :columns="columns"
            :data-source="tableData" :loading="tableLoading" :pagination="pagination" :rowKey="(record,index) => index" @change="handlerPage">
        </a-table>
    </div>
</template>

<script>
    import {
        countryList,
        storehouseFbaLists
    } from "@/api/comm";
    import {
        addressFbaAdd,
        addressFbaEdit
    } from "@/api/Address";
    export default {
        name: "editAddress",
        props: {
            type: {
                type: String,
                default: ""
            },
            mailFbaAddressId: {
                type: [String, Number],
                default: ""
            },
            storehouseFbaId: {
                type: [String, Number],
                default: ""
            }

        },
        watch: {
            storehouseFbaId: {
                handler(val) {
                    this.submitForm.storehouseFbaId = val
                    if (val) {
                        this.selectedRowKeys = [val]
                    }
                },
                immediate: true
            },
            mailFbaAddressId: {
                handler(val) {
                    this.submitForm.mailFbaAddressId = val
                },
                immediate: true
            }
        },
        computed: {
            rowSelection() {
                return {
                    type: "radio",
                    columnTitle: "选择",
                    columnWidth: 70,
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: (selectedRowKeys) => {
                        this.selectedRowKeys = selectedRowKeys;

                    },
                };
            },
        },
        data() {
            return {
                tableData: [],
                tableLoading: false,
                pagination: {
                    current: 1,
                    total: 0,
                    pageSize: 10,
                    showTotal: (total) => `共 ${total} 条`,
                    showSizeChanger: true,
                    showQuickJumper: true,
                },
                selectedRowKeys: [],
                countryList: [],
                form: {
                    countryId: "",
                    limit: 10,
                    page: 1,
                },
                submitForm: {
                    mailFbaAddressId: "",
                    storehouseFbaId: ""
                },
                columns: [{
                        title: "国家",
                        dataIndex: "country_name",
                        width: 100,
                        key: "country_name",
                    },
                    {
                        title: "城市",
                        dataIndex: "city",
                        key: "city",
                    },
                    {
                        title: "州",
                        dataIndex: "state",
                        key: "state",
                    },
                    {
                        title: "仓库名称",
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: "仓库地址",
                        dataIndex: "address",
                        key: "address",
                    },

                ],
            }
        },
        created() {
            this.searchCountry()
        },
        methods: {
            // 获取国家列表
            searchCountry(val) {
                let obj = {
                    name: val,
                    page: 1,
                    limit: 10000,
                };
                countryList(obj).then((res) => {
                    this.countryList = res.data.list;
                });
            },
            handleChange(val) {
                this.form.countryId = val;
            },
            getData() {
                return new Promise((resolve, reject) => {
                    this.tableLoading = true;
                    storehouseFbaLists(this.form)
                        .then((res) => {
                            const arr = res.data.list || [];
                            this.tableData = this.sortArr(arr, 'name');
                            this.pagination.total = res.data.total;
                            this.tableLoading = false;
                            resolve(true);
                        })
                        .catch(() => {
                            reject(false);
                        });
                });
            },
            reset() {
                this.form.keyword = "";
            },
            search() {
                this.form.page = 1;
                this.pagination.current = 1;
                this.getData();
            },
            handlerPage(p) {
                this.form.page = p.current;
                this.form.limit = p.pageSize;
                this.pagination.current = p.current;
                this.pagination.pageSize = p.pageSize;
                this.getData();
            },
            submit() {
                this.submitForm.storehouseFbaId = this.selectedRowKeys.toString() * 1
                if (this.type === 'add') {
                    addressFbaAdd(this.submitForm).then(() => {
                        this.$message.success("地址添加成功");
                        this.$emit("success")
                    })
                } else {
                    addressFbaEdit(this.submitForm).then(() => {
                        this.$message.success("地址编辑成功");
                        this.$emit("success")
                    })
                }

            }
        }
    }
</script>

<style lang="less" scoped>
    .address-container {
        padding-bottom: 30px;
    }
</style>