/*
 * @Author: your name
 * @Date: 2021-12-08 18:10:06
 * @LastEditTime: 2021-12-09 10:27:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/defAddress/curd/columns.js
 */
export default [
    {
        title: "国家",
        dataIndex: "country_name",
        key: "country_name",
    },
    {
        title: "城市",
        dataIndex: "city",
        key: "city",
    },
    {
        title: "州",
        dataIndex: "state",
        key: "state",
    },
    {
        title: "仓库",
        dataIndex: "fba_name",
        key: "fba_name",
    },
    {
        title: "地址",
        dataIndex: "address",
        key: "address",
    },
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
    }
]