/*
 * @Author: your name
 * @Date: 2021-12-08 15:51:06
 * @LastEditTime: 2021-12-08 16:01:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/utils/prarms.js
 */
export function hump(param) {
    Object.keys(param).map(key => {
        let item = param[key];
        if(item instanceof Object || item instanceof Array) {
            hump(item);
        }
        if(hump_(key) !== key) {
            param[hump_(key)] = param[key];
            delete (param[key]);
        }
    });
    return param;
}

function hump_(key) {
    let keyArr = key.split('_');
    for(let i = 0; i < keyArr.length; i++) {
        if(keyArr.includes('url') && keyArr.includes('id')) {
            keyArr[i] = keyArr[i].toUpperCase();
        } else {
            if(i !== 0) {
                keyArr[i] = keyArr[i][0].toUpperCase() + keyArr[i].substr(1);
            }
        }
    }
    return keyArr.join('');
}