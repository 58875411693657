/*
 * @Author: your name
 * @Date: 2021-12-08 18:10:06
 * @LastEditTime: 2021-12-08 18:16:29
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/defAddress/curd/columns.js
 */
export default [
    {
        title: "姓名",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "手机号码",
        dataIndex: "phone",
        key: "phone",
    },
    {
        title: "手机号码二",
        dataIndex: "phone_two",
        key: "phone_two",
    },
    {
        title: "地址",
        dataIndex: "address",
        key: "address",
    },
    {
        title: '操作',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
    }
]