<!--
 * @Author: your name
 * @Date: 2021-12-08 18:22:11
 * @LastEditTime: 2021-12-28 09:53:55
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/defAddress/curd/_edit.vue
-->
<template>
    <div>
        <a-form-model ref="defForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <!--   -->
            <a-form-model-item label="地区" prop="streetId" :rules="validate({ name: 'value', msg: '请选择地区' })">
                <a-cascader v-model="form.city" :options="provinceData" :fieldNames="fieldNames" placeholder="地区"
                    :load-data="loadData" @change="onChange" />
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="name" :rules="validate({ name: 'value', msg: '请输入姓名' })">
                <a-input v-model="form.name" placeholder="姓名" allowClear></a-input>
            </a-form-model-item>
            <a-form-model-item label="手机号" prop="phone" :rules="validate({ name: 'number', msg: '请输入手机号' })">
                <a-input v-model="form.phone" placeholder="手机号" allowClear></a-input>
            </a-form-model-item>
            <a-form-model-item label="手机号二" prop="phoneTwo">
                <a-input v-model="form.phoneTwo" placeholder="手机号二" allowClear></a-input>
            </a-form-model-item>
            <a-form-model-item label="地址" prop="address" :rules="validate({ name: 'value', msg: '请输入地址' })">
                <a-input v-model="form.address" placeholder="地址" allowClear></a-input>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import {
    addressAdd,
    addressInfo,
    addressEdit,
    chinaCascade
} from "@/api/Address";
import {
    hump
} from "@/utils/prarms"
export default {
    props: {
        type: {
            type: String,
            default: "add"
        },
        addressId: {
            type: [String, Number],
            default: ""
        }
    },
    watch: {
        type: {
            handler(val) {
                if (val === 'edit') {
                    addressInfo({
                        addressId: this.addressId
                    }).then(res => {
                        const obj = res.data;
                        this.form = {
                            ...hump(obj)
                        };
                        this.form.addressId = this.addressId;
                        this.form.city = [this.form.provinceId, this.form.cityId, this.form.countyId, this
                            .form.streetId
                        ]
                        this.addressChina();

                    })
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            labelCol: {
                span: 4
            },
            wrapperCol: {
                span: 14
            },
            options: [],
            fieldNames: {
                label: 'name',
                value: 'id',
                children: 'children'
            },
            province: '',
            city: '',
            area: '',
            cityData: [],
            provinceData: [],
            streetData: [],
            areaData: [],
            form: {
                city: [],
                streetId: "",
                name: "",
                phone: "",
                phoneTwo: "",
                address: "",
            },
        };
    },
    created() {
        if (this.type == 'add') this.addressChina()
    },
    methods: {
        loadData(selectedOptions) {
            const targetOption = selectedOptions[selectedOptions.length - 1]
            if (selectedOptions.length < 4) {
                targetOption.loading = true

                chinaCascade({
                    level: selectedOptions.length + 1,
                    parentId: targetOption.id
                }).then(res => {
                    let {
                        data
                    } = res
                    if (selectedOptions.length != 3) {
                        data.map(ele => {
                            ele.isLeaf = false
                            return ele
                        })
                    }

                    targetOption.children = data
                    targetOption.loading = false
                    this.provinceData = [...this.provinceData]

                })
            }
        },

        // 获取省市区
        addressChina() {
            chinaCascade({
                level: 1,
                parentId: 0
            }).then(res => {
                this.provinceData = res.data
                let gd = this.provinceData[18]
                this.provinceData.splice(18, 1)
                this.provinceData.unshift(gd)
                this.provinceData.map(ele => {
                    ele.isLeaf = false
                    return ele
                })
                if (this.type != 'add') {
                    let arr = [chinaCascade({
                        level: 2,
                        parentId: this.form.provinceId
                    }), chinaCascade({
                        level: 3,
                        parentId: this.form.cityId
                    }), chinaCascade({
                        level: 4,
                        parentId: this.form.countyId
                    })]
                    Promise.all(arr).then(res => {
                        let newArr = [...res[0].data]
                        for (let i of newArr) {
                            if (i.id == this.form.cityId) {
                                i.children = res[1].data
                                for (let j of i.children) {
                                    if (j.id == this.form.countyId) {
                                        j.children = res[2].data
                                        break
                                    }
                                }
                                break
                            }
                        }
                        for (let i of this.provinceData) {
                            if (i.id == this.form.provinceId) {
                                i.children = newArr
                                break
                            }
                        }
                        this.provinceData = [...this.provinceData]
                    })

                }

            })
        },
        // 选择地区的效果
        onChange(val) {
            this.provinceData = [...this.provinceData]
            this.form.streetId = val[val.length - 1];
        },
        submit() {
            this.$refs.defForm.validate((valid) => {
                if (valid) {
                    if (this.type === 'add') {
                        addressAdd(this.form).then(() => {
                            this.$message.success("地址添加成功");
                            this.$emit("success")
                        })
                    } else {
                        addressEdit(this.form).then(() => {
                            this.$message.success("地址编辑成功");
                            this.$emit("success")
                        })
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            })
        }
    },
};
</script>

<style lang="less" scoped></style>