<!--
 * @Author: your name
 * @Date: 2021-12-08 10:32:01
 * @LastEditTime: 2021-12-28 16:31:41
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/Address/index.vue
-->
<template>
    <div class="page-container">
        <a-tabs type="card" @change="callback" :default-active-key="getAddressIndex">
            <a-tab-pane key="1" tab="取件地址">
                <defAddress></defAddress>
            </a-tab-pane>
            <a-tab-pane key="2" tab="非FBA收件地址">
                <noFbaAddress></noFbaAddress>
            </a-tab-pane>
            <a-tab-pane key="3" tab="FBA收件地址">
                <fbaAddress></fbaAddress>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import defAddress from "./defAddress"
import fbaAddress from "./fbaAddress"
import noFbaAddress from "./noFbaAddress"
export default {
    name: "Address",
    components: {
        defAddress,
        fbaAddress,
        noFbaAddress
    },
    methods: {
        callback(n) {
            this.setAddressIndex(n)
        }
    }
}
</script>

<style lang="less" scoped>

</style>